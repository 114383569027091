import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import CustomMultipleSelect from '../../../../core/components/CustomMultipleSelect'
import CustomSearch from '../../../../core/components/CustomSearch'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { TApplicationFilters } from '../types'
import { useApplicationsContext } from '../state/useApplications'
import { QueryKeys } from '../queries'

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const FormStyled = styled.form`
  ${flex({ align: 'center' })};
`

const SearchWrapper = styled.div`
  margin-left: -8px;
  padding: 0 8px 5px 0;

  && {
    .MuiOutlinedInput-root,
    .MuiTextField-root {
      width: 100%;
    }

    .MuiButton-root {
      margin-left: 8px !important;
    }
  }
`

export const initialState = {
  sources: []
}

const Filter = () => {
  const queryClient = useQueryClient()
  const {
    sourceFilter,
    setSourceFilter,
    sourceFilterOptions,
    setSourceFilterSearch } = useApplicationsContext()
  const { watch, control, resetField } = useForm<TApplicationFilters>({
    mode: 'onChange',
    defaultValues: initialState
  })

  const sourceValues = watch('sources')

  useEffect(() => {
    if (!sourceValues?.length && sourceFilter?.length) {
      setSourceFilter([])
    }
  }, [sourceValues, sourceFilter])

  const handleResetSourceFilter = () => {
    resetField('sources')
    setSourceFilter([])
    setSourceFilterSearch('')
  }

  return (
    <>
      <TitleStyled>Filters:</TitleStyled>
      <FormStyled id="applicationMultiselectForm">
        <CustomMultipleSelect
          name="sources"
          control={control}
          options={sourceFilterOptions || []}
          title="Source"
          onApply={() => setSourceFilter(sourceValues ?? [])}
          onReset={handleResetSourceFilter}
          isOptionChecked={!!sourceFilter?.length}
          onClose={() => !sourceFilter?.length && resetField('sources')}
          onOpen={() => queryClient.invalidateQueries(QueryKeys.SourceFilter)}
          search={(
            <SearchWrapper>
              <CustomSearch placeholder="Search source..." setSearch={setSourceFilterSearch} />
            </SearchWrapper>
          )}
        />
      </FormStyled>
    </>
  )
}

export default Filter
