import { useCallback, useState } from 'react'
import constate from 'constate'
import { useSetState } from 'react-use'
import { TApplicationState, TFilter } from '../types'
import { useApplicationsQuery, useSourceFilterQuery } from '../queries'
import { TSortBy } from '../../../../core/components/table/TableContext'
import { useIsPlendOrganization } from '../../../auth/hooks'

const initialState: TApplicationState = {
  search: '',
  currentPage: 1,
  sortBy: getInitialSortBy()
}

function getInitialSortBy(filter?: TFilter, isPlendOrganization?: boolean) {
  const createdDate = isPlendOrganization ? 'submissionDate' : 'createdAt'
  return {
    desc: true,
    id: filter === 'APPROVED' ? 'statusAt' : createdDate
  }
}

const useApplications = () => {
  const [sourceFilter, setSourceFilter] = useState<string[]>([])
  const [sourceFilterSearch, setSourceFilterSearch] = useState('')
  const [
    applicationListState,
    setApplicationListState
  ] = useSetState<TApplicationState>(initialState)
  const isPlendOrganization = useIsPlendOrganization()

  const handleChangeFilter = useCallback((filter?: TFilter) => {
    setSourceFilter([])
    setSourceFilterSearch('')
    setApplicationListState({
      ...initialState,
      filter,
      sortBy: getInitialSortBy(filter, isPlendOrganization)
    })
  }, [])

  const setSortBy = useCallback((sort?: TSortBy[]) => {
    const { filter } = applicationListState
    setApplicationListState({
      sortBy: sort?.length ? sort[0] : getInitialSortBy(filter, isPlendOrganization)
    })
  }, [applicationListState.filter])

  const setSearch = useCallback((search: string) => setApplicationListState({ search }), [])

  const setCurrentPage = useCallback(
    (currentPage: number) => setApplicationListState({ currentPage }),
    []
  )

  const { data: sourceFilterOptions } = useSourceFilterQuery({ search: sourceFilterSearch })

  return {
    ...applicationListState,
    sourceFilter,
    sourceFilterSearch,
    setSortBy,
    setSearch,
    setCurrentPage,
    setSourceFilter,
    setSourceFilterSearch,
    sourceFilterOptions,
    applicationsQuery: useApplicationsQuery(applicationListState, sourceFilter),
    handleChangeFilter
  }
}

export const [ApplicationsProvider, useApplicationsContext] = constate(useApplications)
